<template>
    <div class="va-answer-page" ref="content">
        <div class="wrapper">
            <div v-if="isRequestFinished && init && engineInit">
                <single-page-notification :notification="response.data.etv.notification"></single-page-notification>
                <single-page-breadcrumbs></single-page-breadcrumbs>
                <h2>{{response.data.etv.originalquestion}}</h2>
                <single-page-answer :response="response.data.answer" :semanticsfaqs="response.data.semantics"></single-page-answer>
                <single-page-inline-search :flag="response.data.etv.inline_search"></single-page-inline-search>
                <answer-page-faq-selector v-if="this.showFaqs" :faqs="response.data.questions"></answer-page-faq-selector>
                <single-page-survey v-if="this.offerIcs()" :sid="response.data.ident" :tid="response.data.etv.transid"></single-page-survey>
                <single-page-footer></single-page-footer>
            </div>
            <div v-else>
                <main-ajax-spinner></main-ajax-spinner>
            </div>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';
import router from '../router';
import tealium from '../mixins/tealium.js';

import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';
import SinglePageSurvey from '@/components/SinglePageSurvey';
import AnswerPageFaqSelector from '@/components/AnswerPageFaqSelector';
import MainAjaxSpinner from '@/components/MainAjaxSpinner';
import SinglePageAnswer from '@/components/SinglePageAnswer';
import SinglePageFooter from '@/components/SinglePageFooter';
import SinglePageNotification from '@/components/SinglePageNotification';
import SinglePageInlineSearch from '@/components/SinglePageInlineSearch';
//import CookiePolicy from '@/components/CookiePolicy';

export default {
    name: 'ViewAnswerPage',
    props: ['cat1', 'cat2', 'cat3', 'articletitle'],
    components: {
        SinglePageBreadcrumbs,
        MainAjaxSpinner,
        SinglePageAnswer,
        SinglePageFooter,
        SinglePageSurvey,
        AnswerPageFaqSelector,
        SinglePageNotification,
        SinglePageInlineSearch,
        //CookiePolicy
    },
    mixins: [tealium],
    data() {
        return {
            showFaqs: true,
            navto: {}, // for Tealium tracking purposes
            debug: false,
            hash:''
        }
    },
    
    updated () {
       this.scrollToHash()
    },

    watch: {
        init(n) {
            this.debug && console.log('e02');
            n && this.categoryInit && Event.$emit('va.request', {type: 'jumpToQuestion', category: this.getPath(), question: this.getQuestion()});
        },

        categoryInit(n) {
            this.debug && console.log('e03');
            n && this.init && Event.$emit('va.request', {type: 'jumpToQuestion', category: this.getPath(), question: this.getQuestion()});
        },

        response() {

            // First check is for autonav when you hit an answer page directly
            // If it's empty, we continue per usual
            if(this.response.data.autonav.href.length > 0) {
                router.push({path: this.response.data.autonav.href});
            }
            else {
                switch(this.response.data.etv.answertype) {
                    case 'Safety Net': // if this check kicks in, we assume that URL has been modified manually and is incorrect, so we redirect to 404
                    router.push({path: '/404'});
                    break;
                    case 'Keyword':
                        this.showFaqs = false;
                        break;
                    default:
                        this.showFaqs = true;
                }

                /*
                let searchRef = '';
                if(typeof this.navto.query.rf != 'undefined') {
                    (this.navto.query.rf == 'main') && (searchRef = 'StartPage'); // start page search
                    (this.navto.query.rf == 'Nav') && (searchRef = this.navto.query.rf); // answer inline search
                }
                else {
                    searchRef = 'Main'; // main menu search
                }
                */

                let searchStatus = {
                    ref: (typeof this.navto.query.rf != 'undefined') ? 'Nav' : '',
                    answerData: {
                        maincat: this.response.data.maincat,
                        title: this.response.data.etv.originalquestion
                    }
                };

                this.tealiumRouteTracking(this.navto, searchStatus);
            }
        },
    },

    created() {
        if(this.isSessionValid() && this.categoryInit) {
            this.debug && console.log('e04');
            if(this.faqId !== '') { // this is a Back nav check
                Event.$emit('va.request', {type: 'selectFaq', id: this.faqId});
            }
            else {
                Event.$emit('va.request', {type: 'jumpToQuestion', category: this.getPath(), question: this.getQuestion()});
            }

        }
    },

    computed: {
        categoryInit() {
            return store.state.categoriesInit;
        },

        init() {
            return store.state.init;
        },

        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response() {
            return store.state.responseData;
        },

        faqId() {
            return store.state.faqDestinationId;
        },

        token() {
            return store.state.token;
        },
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next(vm => {
            window.scrollTo(0, 0);
            vm.navto = to;
            //vm.tealiumRouteTracking(to);
            if(vm.hash == '')
                vm.hash = to.hash
        });
    },

    beforeRouteUpdate(to, from, next) {
        this.debug && console.log('e05');
        if(this.faqId !== '') { // this is a Back nav check
            Event.$emit('va.request', {type: 'selectFaq', id: this.faqId});
        }
        else {
            Event.$emit('va.request', {type: 'jumpToQuestion', category: this.getPath(), question: this.getQuestion()});
        }
        window.scrollTo(0, 0);
        store.dispatch('setSearchStatus', false); // reset search status
        this.navto = to;
        //this.tealiumRouteTracking(to);
        next();
    },

    methods: {
        isSessionValid() {
            let valid = false;
            let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
            
            if(session && this.token.length > 0) {
                valid = true;
            }
            else {
                this.debug && console.log('e01');
                Event.$emit('va.init', {init: 1});
            }
            
            return valid;
        },
        
        offerIcs() {
            if(this.response.data.etv.showicsoverlay == '1') {
                return true;
            }
            else {
                return false;
            }
        },

        getQuestion() {
            let question = '';
            question = this.articletitle.replace(/-/g, ' ');
            return question;
        },

        getPath() {
            var path = 'Root';

            let c1 = typeof this.cat1 !== 'undefined' ? true: false;
            let c2 = typeof this.cat2 !== 'undefined' ? true: false;
            let c3 = typeof this.cat3 !== 'undefined' ? true: false;

            if(c1) {
                var c1Name = this.cat1.replace(/-/g, ' ');
            }

            if(c2) {
                var c2Name = this.cat2.replace(/-/g, ' ');
            }

            if(c3) {
                var c3Name = this.cat3.replace(/-/g, ' ');
            }

            if(c1) {
                for(let key1 in store.state.categories) {
                    for(let key2 in store.state.categories[key1]) {
                        (key2.toLowerCase() == c1Name) && (path = path + '.' + key2);
                        if(c2) {
                            for(let key3 in store.state.categories[key1][key2]) {
                                for(let key4 in store.state.categories[key1][key2][key3]) {
                                    (key4.toLowerCase() == c2Name) && (path = path + '.' + key4);
                                    if(c3) {
                                        for(let key5 in store.state.categories[key1][key2][key3][key4]) {
                                            for(let key6 in store.state.categories[key1][key2][key3][key4][key5]) {
                                                (key6.toLowerCase() == c3Name) && (path = path + '.' + key6);
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
            }
            return path;
        },
        scrollToHash(){
            let hash = this.hash.replace("#", "")
            let hashElement = document.getElementById(hash)
            if(hashElement)
                hashElement.scrollIntoView();
        }
    },
}
</script>

<style scoped>

    .wrapper {
        min-height: calc(100vh - 240px);
        position: relative;
        max-width: 930px;
        margin: 0 auto;
        padding-top: 30px;
        padding-top: 15px;
    }

    h2 {
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 3em;
        font-weight: 500;
        color: #05286A;
        padding: 0 0 16px 0;
        margin: 0;
        line-height: 54px;
    }

    @media only screen and (max-width: 966px) {
        .va-answer-page {
            padding: 0 18px 0 18px;
        }
    }

</style>


