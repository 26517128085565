<template>
    <div class="related-questions">
        <div class="wrapper">
            <div class="faq-links">
                <main-page-faq-selector-quick-links :faqs="response.data.etv.quicklinks"></main-page-faq-selector-quick-links>
                <main-page-faq-selector-topics :topics="faqs"></main-page-faq-selector-topics>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store';

import MainPageFaqSelectorQuickLinks from '@/components/MainPageFaqSelectorQuickLinks';
import MainPageFaqSelectorTopics from '@/components/MainPageFaqSelectorTopics';

export default {
    name: 'mainPageFaqSelector',
    components: {
        MainPageFaqSelectorQuickLinks,
        MainPageFaqSelectorTopics
    },
    props: ['faqs'],
    computed: {
        response () {
            return store.state.responseData;
        },
    }
}
</script>

<style scoped>

    .wrapper {
        max-width: 930px;
        margin: 30px auto 0 auto;
    }

    .faq-links {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: 0 0 30px 0;
    }

    @media only screen and (max-width: 966px) {
        .related-questions {
            padding: 0 18px 0 18px;
        }
    }

</style>
