<template>
    <div v-if="show" class="message" :class="[this.type]">
        <!--{{message}}-->
        <div v-html="this.message"></div>
        <button class="notification-close" @click.prevent="closeNotification()">Close notification</button>
    </div>
</template>

<script>
export default {
    name: 'SinglePageNotificationItem',
    props: ['message', 'type'],
    data() {
        return {
            class: '',
            show: true,
        }
    },

    created: function() {
        if(this.type == 'browser') {
            this.class = 'browser';
            sessionStorage.notification_browser = 1;
        }
        
        if(this.type == 'negative') {
            this.class = 'negative';
            sessionStorage.notification_negative = 1;
        }
    },
    
    methods: {
        closeNotification: function() {
            if(this.class == 'negative') {
                sessionStorage.notification_negative = 0;
            }
            if(this.class == 'browser') {
                sessionStorage.notification_browser = 0;
            }
            this.show = false;
        },
    },
}
</script>

<style scoped>
    .message {
        margin-bottom: 30px;
        padding: 18px 48px 18px 18px;
        color: #323233;
        font-size: 1.125em;
        position: relative;
    }

    .message >>> p {
        margin: 0;
        padding: 0;
    }

    .negative {
        background: #F4BEC9;
    }

    .browser {
        background: #F1C7B2;
    }

    .browser .notification-close {
        display: none;
    }

    .notification-close {
        text-indent: -9999px;
        position: absolute;
        right: 18px;
        top: 22px;
        border: none;
        background: none;
        width: 18px;
        height: 18px;
    }

    .notification-close:hover {
        cursor: pointer;
    }

    .notification-close:before, .notification-close:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 18px;
        width: 3px;
        background: #323233;
    }

    .notification-close:before {
        transform: rotate(45deg);
    }

    .notification-close:after {
        transform: rotate(-45deg);
    }

</style>
