import Vue from 'vue';
import Router from 'vue-router';
import ViewMainPage from '@/components/ViewMainPage';
import ViewCategoryPage from '@/components/ViewCategoryPage';
import ViewAnswerPage from '@/components/ViewAnswerPage';
import ViewNotFoundPage from '@/components/ViewNotFoundPage';
import ViewSearchPage from '@/components/ViewSearchPage';
import ViewVideosPage from '@/components/ViewVideosPage';
import ViewUnavailablePage from '@/components/ViewUnavailablePage';
import ViewWhatsnewPage from '@/components/ViewWhatsnewPage';
import ViewWebinarsPage from '@/components/ViewWebinarsPage';
import ViewThankYouFeedback from '@/components/ViewThankYouFeedback';

Vue.use(Router);

const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "home",
            component: ViewMainPage
        },
        {
            path: "/404",
            name: "404",
            component: ViewNotFoundPage
        },
        {
            path: "/search",
            name: "search",
            component: ViewSearchPage,
            props: (route) => ({query: route.query.q, rf: route.query.rf}),
            meta: {title: 'Search'}
        },
        {
            path: "/videos",
            name: "videos",
            component: ViewVideosPage
        },
        {
            path: '/article/videos',
            redirect: '/videos'
        },

        {
            path: "/service-unavailable",
            name: "serviceunavailable",
            component: ViewUnavailablePage
        },
        {
            path: '/article/service-unavailable',
            redirect: '/service-unavailable'
        },

        {
            path: "/webinars",
            name: "webinars",
            component: ViewWebinarsPage
        },
        {
            path: '/article/webinars',
            redirect: '/webinars'
        },

        {
            path: "/activate",
            name: "activate",
            component: ViewAnswerPage,
            props: {
                cat1: 'administration',
                cat2: 'user-management',
                cat3: 'security-cards',
                articletitle: 'how-to-activate-your-security-card'
            }
        },

        {
            path: "/whats-new",
            name: "whatsnew",
            component: ViewWhatsnewPage
        },

        {
            path: "/thank-you-feedback",
            name: "thankyoufeedback",
            component: ViewThankYouFeedback
        },

        {
            path: "/:cat1?/:cat2?/:cat3?/:cat4?/article/:articletitle",
            name: "article",
            component: ViewAnswerPage,
            props: true,
        },
        {
            path: "/:cat1?/:cat2?/:cat3?",
            name: "category",
            component: ViewCategoryPage,
            props: true,
        },
        {
            path: "*",
            name: "notfound",
            component: ViewNotFoundPage
        },
    ]
});

export default router;