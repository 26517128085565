<template>
    <div class="panel">
        <h4>In this section</h4>
        <ul>
            <li v-for="(item, index) in faqs" :key="index">
                <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{item.faqdisplay}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'ViewCategoryFaqPanel0',
    computed: {
        faqs: function() {
            return store.state.responseData.data.questions;
        },

        faqList: function() {
            let arr = this.faqs;
            let half = Math.floor(arr.length / 2)
            let first = arr.slice(0, half);
            //let second = arr.slice(half, arr.length);           
            return first;
        },
    },
    methods: {
        submitRequest: function(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);
            router.push({path: '/' + faq.path + '/article/' + title});
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
}
</script>

<style scoped>
    .panel {
        width: 100%;
        padding: 30px;
        background: #ffffff;
        margin: 0 0 30px 0;
        /*box-shadow: inset 0 4px 0 0 #005BA2, 0 0 5px 0 rgba(173,173,173,0.5);*/
    }

    .panel h4 {
        /*
        font-family: 'LBJ Medium', Arial, Helvetica, sans-serif;
        color: #323233;
        font-size: 1.5em;
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
        font-weight: normal;
        */
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        color: #323233;
        font-size: 1.5em;
        margin: 0 0 15px 0;
        font-weight: normal;
    }

    .panel ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.0625em;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .panel ul li {
        width: 50%;
    }

    .panel ul li a {
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        text-decoration: underline;
        padding: 0 0 10px 0;
        display: block;
        position: relative;
    }

    .panel ul li a:hover {
        text-decoration: none;
    }

    .panel ul li a:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-50%) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 0 0 -5px 10px;
    }

    @media only screen and (max-width: 650px) {
        .panel {
            width: 100%;
        }

        .panel ul li {
            width: 100%;
        }
    }
</style>
