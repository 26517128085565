<template>
    <div class="ics">
        <div class="wrapper">
            <template v-if="node == 0">
                <h4>Was this page helpful?</h4>
                <div class="ics-buttons">
                    <button @click="switchView(1)" data-tealium-narrative="Positive Article Feedback">Yes</button>
                    <button @click="switchView(2)" data-tealium-narrative="Negative Article Feedback">No</button>
                </div>
            </template>

            <template v-if="node == 1">
                <h4>Thank you for your feedback.</h4>
            </template>

            <template v-if="node == 2">
                <h4 class="feedback">Sorry we haven't been able to help you. Please tell us what you were looking for so we can improve our site.</h4>
                <textarea class="ics-feedback" v-model="feedback" data-tealium-id="articlefeedback" data-tealium-tracking="none"></textarea>
                <button class="ics-submit" @click.prevent="switchView(3)" data-tealium-tracking="full" data-tealium-value="$articlefeedback" data-tealium-narrative="Article Feedback Text">Submit</button>
            </template>

            <template v-if="node == 3">
                <h4>Thank you for your feedback.</h4>
            </template>
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import tealium from '../mixins/tealium.js';

export default {
    name: 'SinglePageSurvey',
    props: ['sid', 'tid'],
    mixins: [tealium],
    data() {
        return {
            node: 0,
            feedback: '',
        }
    },

    created: function() {
        let data = {};
        data.sid = this.sid;
        data.tid = this.tid;
        data.jsonIcs = JSON.stringify({"show_ics":"true"});
        data.type = 'ics';
        Event.$emit('va.request', data);
    },

    methods: {
        switchView(n) {
            switch(n) {
                case 0:
                    this.node = n;
                    break;
                case 1:
                    this.submitIcsRequest({"show_ics":"true", "helped":"yes"});
                    this.node = n;
                    this.tealiumIcsTracking("Yes");
                    break;
                case 2:
                    this.submitIcsRequest({"show_ics":"true", "helped":"no"});
                    this.node = n;
                    this.tealiumIcsTracking("No");
                    break;
                case 3:
                    if(!this.feedback) {
                        return false;
                    }
                    this.submitIcsRequest({"show_ics":"true", "helped":"no", "Comment": this.feedback});
                    this.node = n;
                    this.tealiumIcsTracking(this.feedback);
                    break;
            }
        },

        submitIcsRequest(icsData) {
            let data = {};
            data.sid = this.sid;
            data.tid = this.tid;
            data.jsonIcs = JSON.stringify(icsData);
            data.type = 'ics';
            Event.$emit('va.request', data);
        }
    }
}
</script>

<style scoped>

    .ics {
        margin: 30px 0 30px 0;
        background: #ffffff;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        padding: 25px;
    }

    h4 {
        font-size: 1.125em;
        font-weight: normal;
        color: #323233;
        display: inline-block;
        line-height: 0;
    }

    h4.feedback {
        display: block;
    }

    .ics-buttons {
        display: inline-block;
    }

    .ics-buttons button {
        display: inline-block;
        border: 2px solid #005BA2;
        background: #ffffff;
        text-align: center;
        font-size: 1.0625em;
        color: #005BA2;
        margin-left: 20px;
        transition: all .2s;
        line-height: 48px;
        height: 48px;
        width: 60px;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .ics-buttons button:hover {
        cursor: pointer;
        background: #005BA2;
        color: #ffffff;
    }

    textarea.ics-feedback {
        width: 40%;
        height: 135px;
        border: 1px solid #767676;
        padding: 15px;
        resize: none;
        color: #767676;
    }

    button.ics-submit {
        display: block;
        background: #048161;
        color: #ffffff;
        width: 96px;
        height: 48px;
        line-height: 48px;
        transition: all .2s;
        margin-top: 15px;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        font-size: 1.0625em;
        border: none;
    }

    button.ics-submit:hover {
        cursor: pointer;
        /*
        background: #005BA2;
        color: #ffffff;
        */
    }

</style>
