<template>
    <div class="search-page" ref="content">
        <div class="wrapper">
            <div v-if="isRequestFinished && init && engineInit">
                <single-page-notification :notification="response.data.etv.notification"></single-page-notification>
                <single-page-breadcrumbs></single-page-breadcrumbs>

                <template v-if="!this.sn">
                    <h2>{{getQuestion}}</h2>
                    <single-page-answer :response="response.data.answer" :semanticfaqs="response.data.semantics"></single-page-answer>
                    <answer-page-faq-selector v-if="this.showFaqs" :faqs="response.data.questions"></answer-page-faq-selector>
                </template>
                <template v-else>
                    <h2>No results found</h2>
                    <div class="not-found-panel">
                        <h4>We couldn't find a match. Check your spelling or try using different words.</h4>
                        <main-page-faq-selector :faqs="response.data.questions"></main-page-faq-selector>
                    </div>
                </template>

                <single-page-survey v-if="this.offerIcs()" :sid="response.data.ident" :tid="response.data.transid"></single-page-survey>
                <single-page-footer></single-page-footer>
            </div>
            <div v-else>
                <main-ajax-spinner></main-ajax-spinner>
            </div>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';
import router from '../router';
import tealium from '../mixins/tealium.js';

import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';
import SinglePageSurvey from '@/components/SinglePageSurvey';
import AnswerPageFaqSelector from '@/components/AnswerPageFaqSelector';
import MainAjaxSpinner from '@/components/MainAjaxSpinner';
import SinglePageAnswer from '@/components/SinglePageAnswer';
import SinglePageFooter from '@/components/SinglePageFooter';
import SinglePageNotification from '@/components/SinglePageNotification';
//import CookiePolicy from '@/components/CookiePolicy';
import MainPageFaqSelector from '@/components/MainPageFaqSelector';

export default {
    name: 'ViewSearchPage',
    components: {
        SinglePageBreadcrumbs,
        MainAjaxSpinner,
        SinglePageAnswer,
        SinglePageFooter,
        SinglePageSurvey,
        AnswerPageFaqSelector,
        SinglePageNotification,
        MainPageFaqSelector
        //CookiePolicy
    },
    mixins: [tealium],
    props: ['query', 'rf'],
    data() {
        return {
            showFaqs: true,
            q: this.query,
            //ref: this.rf,
            debug: false,
            sn: false,      // did we hit a safety net?
            navto: {}       // this is for Tealium tracking purposes 
        }
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        
        next(vm => {
            window.scrollTo(0, 0);
            vm.navto = to;
            //vm.tealiumRouteTracking(to);
        });
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        //this.tealiumRouteTracking(to);
        window.scrollTo(0, 0);
        this.navto = to;
        next();
    },

    watch: {
        init(n) {
            this.debug && console.log('e02');
            //n && !store.state.engineInit && Event.$emit('va.request', {type: 'freetext', entry: this.query});
            n && Event.$emit('va.request', {type: 'freetext', entry: this.query});
        },

        query(n) {
            this.debug && console.log('e03');
            n && Event.$emit('va.request', {type: 'freetext', entry: n});
        },

        response(n) {

            //console.log(this.response.data.maincat);

            let searchRef = '';
            if(typeof this.navto.query.rf != 'undefined') {
                /*
                (this.navto.query.rf == 'main') && (searchRef = 'HomePage'); // start page search
                (this.navto.query.rf == 'Nav') && (searchRef = this.navto.query.rf); // answer inline search
                */
               searchRef = this.navto.query.rf;
            }
            else {
                searchRef = 'Nav'; // main menu search
            }

            let searchStatus = {
                sn: false,
                semantics: false,
                ref: searchRef,
                answerData: {
                    maincat: this.response.data.maincat,
                    title: this.response.data.etv.originalquestion
                }
            };

            switch(this.response.data.etv.answertype) {
                case 'Safety Net':
                    this.showFaqs = false;
                    this.sn = this.response.data.semantics.length == 0 ? true : false;

                    searchStatus.sn = true;
                    searchStatus.semantics = !this.sn;

                    break;
                case 'Keyword':
                    this.sn = false;
                    this.showFaqs = false;
                    break;
                default:
                    this.sn = false;
                    this.showFaqs = true;
            }

            this.tealiumRouteTracking(this.navto, searchStatus);

            (this.response.data.autonav.href.length > 0) && router.push({path: this.response.data.autonav.href});
        },
    },

    methods: {
        isSessionValid() {
            let valid = false;
            let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
            
            if(session && this.token.length > 0) {
                valid = true;
            }
            else {
                this.debug && console.log('e01');
                Event.$emit('va.init', {init: 1});
            }
            
            return valid;
        },
        
        offerIcs() {
            if(this.response.data.etv.showicsoverlay == '1') {
                return true;
            }
            else {
                return false;
            }
        },
    },

    created() {
        this.debug && console.log('[SEARCH QUERY]]: %s', this.query);
        this.isSessionValid() && Event.$emit('va.request', {type: 'freetext', entry: this.query});
    },

    computed: {
        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        init() {
            return store.state.init;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response() {
            return store.state.responseData;
        },

        token() {
            return store.state.token;
        },

        getQuestion() {
            if(this.response.data.etv.answertype == 'Normal' && this.response.data.etv.originalquestion != 'Safety Net') {
                return this.response.data.etv.originalquestion;
            }
            else {
                return this.query;
            }
        }
    },
}
</script>

<style scoped>

    .wrapper {
        min-height: calc(100vh - 240px);
        position: relative;
        max-width: 930px;
        margin: 0 auto;
        padding-top: 15px;
    }

    h2 {
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 3em;
        font-weight: 500;
        color: #05286A;
        padding: 0 0 16px 0;
        margin: 0;
        line-height: 54px;
    }

    .not-found-panel {
        width: 100%;
        /*padding: 30px;*/
        background: #ffffff;
        margin: 0 0 30px 0;
        /*box-shadow: inset 0 4px 0 0 #006A4D, 0 0 5px 0 rgba(173,173,173,0.5);*/
    }

    .not-found-panel h4 {
        font-size: 1.75em;
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-weight: bold;
        padding: 25px 25px 0 25px;
        margin: 0;
        color: #05286A;
    }

    .not-found-panel a {
        font-size: 1.125em;
    }

    .not-found-panel p {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        color: #505050;
        font-size: .9375em;
        margin: 0 0 30px 0;
    }

    .not-found-panel a:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: translateY(-50%) rotate(45deg);
        color: #006A4D;
        margin: 0 0 -4px 5px;
    }

    @media only screen and (max-width: 966px) {
        .search-page {
            padding: 0 18px 0 18px;
        }
    }

</style>
