<template>
    <div class="panel">
        <h2>Error</h2>
        <p>System did not respond correctly to your request. In order to continue, please click the Continue button.</p>
        <button @click.prevent="handleError()">Continue</button>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';
import router from '../router';

export default {
    name: 'MainModalWindowPanel',
    props: ['message'],
    methods: {
        handleError() {
            let error = {
                show: false,
                reason: '',
                code: 0,
                message: '',
                retries: 0
            };
            store.dispatch('setToken', '');
            store.dispatch('setError', error);

            router.push({path: '/'});
            Event.$emit('va.init', {init: 1});

            /*
            switch(this.message.code) {
                case 4:
                    console.log(1);
                    break;
            }
            */
        },
    },
}
</script>

<style scoped>

    .panel {
        background: #ffffff;
        padding: 30px 25px 30px 25px;
        width: 600px;
        height: 400px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 101;
    }

    .panel h2 {
        font-size: 1.5em;
        color: #323233;
        padding: 0;
        margin: 0 0 15px 0;
        font-weight: normal;
    }

    .panel p {
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        font-size: .9375em;
    }

    .panel button {
        background: #006A4D;
        color: #ffffff;
        padding: 12px 20px 12px 20px;
        font-family: 'LBJ Light', Arial, Helvetica, sans-serif;
        font-size: 1.125em;
        border: none;
        position: absolute;
        right: 25px;
        bottom: 30px;
    }

    .panel button:hover {
        cursor: pointer;
    }

</style>
