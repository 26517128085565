<template>
    <div class="category-description" ref="content">
        <div v-html="categoryDescription"></div>    
    </div>
</template>

<script>
import store from '../store';

export default {
    name: 'viewCategoryPageDescription',
    computed: {
        categoryDescription() {
            return store.state.responseData.data.etv.catdescription;
        }
    },
    mounted() {

        // video setup and injection
        const videos = this.$refs['content'].querySelectorAll('.video');
        videos.forEach(video => {
            if(video.getAttribute('data-guid') !== null && video.getAttribute('data-guid') != '') {
                KV.widget({
                    guid: video.getAttribute('data-guid'),
                    type: video.getAttribute('data-type') !== null ? video.getAttribute('data-type') : '',
                    selector: video,
                    width: video.getAttribute('data-width') !== null ? video.getAttribute('data-width') : '',
                    height: video.getAttribute('data-height') !== null ? video.getAttribute('data-height') : '',
                    hideEmbedButton: true,
                    hideDownloadButton: true,
                });
            }
        });
    }
}
</script>

<style scoped>

    .category-description >>> * {
        color: #333333;
        font-size: 1.125rem;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
    }

    .category-description {
        margin-bottom: 30px;
        background: #ffffff;
        padding: 24px;
    }

    .category-description:after {
        content: '';
        display: block; 
        height: 0; 
        clear: both;
    }

    .category-description >>> p:first-of-type {
        margin-top: 0;
    }

    .category-description >>> p:last-child {
        margin-bottom: 0;
    }

    .category-description >>> a {
        color: #005BA2;
        text-decoration: underline;
    }

    .category-description >>> a:hover {
        text-decoration: none;
    }

    .category-description >>> .container {
        box-shadow: inset 0 4px 0 0 #005BA2, 0 0 5px 0 rgba(173,173,173,0.5);
        padding: 24px;
        background: #ffffff;
        margin-bottom: 30px;
    }

    .category-description >>> .container h3 {
        /*font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;*/
        color: #323233;
        font-size: 1.5em;
        font-weight: normal;
        margin-top: 0;
    }

    .category-description >>> .container * {
        font-size: .9375em;
    }

    .category-description >>> .container ol, .category-description >>> .container ul {
        padding: 0;
        margin: 0 0 0 13px;
    }

    .category-description >>> .container li {
        padding: 0 0 0 10px;
        margin: 0 0 20px 0;
    }

    .category-description >>> .container img {
        display: inline-block;
        margin: 0 20px 0 0;
    }



    .category-description >>> .intro, .category-description >>> .lead {
        clear: both;
        overflow: auto;
    }

    .category-description >>> .video {
        width: 100%;
    }

    .category-description >>> .video-container p {
        margin: 15px 0 0 0;
        font-size: .875em;
        line-height: 90%;
        font-family: 'Avenir Regular', Arial, Helvetica, sans-serif;
    }

    .category-description >>> .video-container.left {
        float: left;
        margin: 0 30px 30px 0;
        width: 48%;
    }

    .category-description >>> .video-container.right {
        float: right;
        margin: 0 0 0 30px;
        width: 48%;
    }

    .category-description >>> .original-image {
        width: auto !important;
    }

    .category-description >>> b {
        font-weight: 600;
    }
	
    .category-description >>> .primary-box {
        background: #ffffff;
        padding: 24px 0 24px 97px;
        margin-bottom: 30px;
        position: relative;
    }

    .category-description >>> .primary-box:before {
        content: '';
        position: absolute;
        top: 36px;
        left: 0;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/sprite.png');
        background-size: 300px 300px;
        background-position: -240px -220px;
        width: 59px;
        height: 68px;
    }

    .category-description >>> .primary-box * {
        color: #333333;
        /*font-size: .875em;*/
    }

    .category-description >>> .primary-box h3 {
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 0;
        color: #05286A;      
    }

    .category-description >>> .primary-box p {
        font-size: .9375em;
    }

    .category-description >>> .primary-box .nav-link, .category-description >>> .primary-box a {
        position: relative;
        font-size: 1.0625rem;
        font-weight: 500;
        transition: all .2s;
        font-family: 'Avenir Demi', Arial, Helvetica, sans-serif;
        text-decoration: underline;
        color: #005BA2;
    }

    .category-description >>> .primary-box .nav-link:after, .category-description >>> .primary-box a:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        transform: translateY(-1px) rotate(45deg) skew(-6deg, -6deg);
        color: #005BA2;
        margin: 0 0 -1px 10px;
        transition: all .2s;
    }

    .category-description >>> .primary-box .nav-link:hover, .category-description >>> .primary-box a:hover {
        text-decoration: none;
    }



    @media only screen and (max-width: 449px) {
        .category-description >>> .video-container.right,  .category-description >>> .video-container.left {
            width: 100%;
            float: none;
            margin: 30px 0 30px 0;
        }
    }

</style>
