<template>
    <div class="global-ics-survey gs-ics-survey" :class="{active: open}" v-if="offerSurvey">

        <div class="gs-trigger" role="button" aria-label="Open the feedback survey" @click.prevent="changeState(true)">
            <h2 class="gs-trigger-header">Give Feedback</h2>
        </div>

        <div class="gs-body" :class="{ dismissed : (toggle == false) }">
            <div class="va-wrapper">
            
              <template v-if="node == 0">
                <div class="va-ics-buttons-wrapper">
                  <h4>Is this page helpful?</h4>
                  <div class="va-ics-buttons">
                    <button
                      data-tealium-narrative="Positive Article Feedback"
                      @click="switchView(1)"
                    >
                      Yes
                    </button>
                    <button
                      data-tealium-narrative="Negative Article Feedback"
                      @click="switchView(2)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </template>

              <template v-if="node == 1">
                <div class="va-ics-thankyou">
                  <div class="va-ics-checkmark" />
                  <h4 class="va-ics-h4-thankyou">
                    Thanks for your feedback.
                  </h4>
                  <div
                    class="va-ics-close"
                    @click="toggle = false"
                  />
                </div>
              </template>

              <template v-if="node == 2">
                <div class="va-ics-thankyou">
                  <div class="va-ics-checkmark" />
                  <h4 class="va-ics-h4-thankyou">
                    Thanks for your feedback.
                  </h4>
                  <div
                    class="va-ics-close"
                    @click="switchView(3)"
                  />
                </div>

                <div class="va-ics-comment-form-wrapper">
                  <h4
                    class="va-feedback"
                  >
                    Sorry we haven't been able to help you this time. 
                  </h4>
                  <h4
                    class="va-feedback-2"
                  >
                    Can you tell us what you were looking for? <span>(Optional)</span>
                  </h4>
                  <textarea
                    v-model="feedback"
                    class="va-ics-feedback"
                    data-tealium-id="articlefeedback"
                    data-tealium-tracking="none"
                  />
                  <button
                    class="va-ics-submit"
                    data-tealium-tracking="full"
                    data-tealium-value="$articlefeedback"
                    data-tealium-narrative="Article Feedback Text"
                    @click.prevent="switchView(3)"
                  >
                    Submit
                  </button>
                </div>
              </template>

              <template v-if="node == 3">
                <div class="va-ics-thankyou">
                  <div class="va-ics-checkmark" />
                  <h4 class="va-ics-h4-thankyou">
                    Thanks for your feedback.
                  </h4>
                  <div
                    class="va-ics-close"
                    @click="toggle=false"
                  />
                </div>
              </template>
            </div>

        </div>
    </div>    
</template>

<script>
import {Event} from "../event";
import store from '../store';
import tealium from '../mixins/tealium.js';

export default {
    name: 'GlobalICSSurvey',
    mixins: [tealium],
    props: ['sid', 'tid'],
    data() {
        return {
            open: false,
            hasOpened: false,
            submitted: false,
            offerSurvey: false,
            hasOffered: false,
            node: 0,
            feedback: '',
            toggle:true
        }
    },

    created: function() {
        let data = {};
        data.sid = this.sid;
        data.tid = this.tid;
        data.jsonIcs = JSON.stringify({"show_ics":"true"});
        data.type = 'ics';
        this.offerSurvey = true;
        Event.$emit('va.request', data);
    },

    watch: {
        $route (to, from){
            this.node = 0;
            this.toggle = true;
            this.open = false
        },
    },


    methods: {

        changeState(state) {
            if(state && !this.hasOpened) {
                this.hasOpened = true;
            }

            // hide the suvey dialog once it has been submitted
            if(this.submitted) {
                this.offerSurvey = false;
            }

            this.open = state;
        },

        switchView(n) {
          switch(n) {
              case 0:
                  this.node = n;
                  break;
              case 1:
                  this.submitIcsRequest({"show_ics":"true", "helped":"yes"});
                  this.node = n;
                  this.tealiumIcsTracking("Yes");
                  break;
              case 2:
                  this.submitIcsRequest({"show_ics":"true", "helped":"no"});
                  this.node = n;
                  this.tealiumIcsTracking("No");
                  break;
              case 3:
                  /* TECH-6810
                  if(!this.feedback) {
                    return false;
                  }
                  */
                  this.submitIcsRequest({"show_ics":"true", "helped":"no", "Comment": this.feedback});
                  this.node = n;
                  this.tealiumIcsTracking(this.feedback);
                  if(!this.feedback)
                    this.toggle = false;
                  break;
          }
        },

        submitIcsRequest(icsData) {
          let data = {};
          data.sid = this.sid;
          data.tid = this.tid;
          data.jsonIcs = JSON.stringify(icsData);
          data.type = 'ics';
          Event.$emit('va.request', data);
        },

    }
}
</script>

<style scoped>


    @keyframes windowfade {
        from {
            transform: translateY(20px);
            opacity: 0;
            display: none;
        }

        to {
            transform: translateY(0);
            opacity: 1;
            display: block;
        }
    }



    .gs-trigger {
        /*width: 159px;*/
        /*height: 52px;*/
        background-color: #2178b0;
        position: fixed;
        right: 20px;
        bottom: 20px;
        display: block;
        /*-webkit-box-shadow: 0 0 5px 0 rgb(173 173 173 / 50%);*/
        /*box-shadow: 0 0 5px 0 rgb(173 173 173 / 50%);*/
        border: 2px solid #05286A;
        background: #05286A;
        line-height: normal;
    }

    .active .gs-trigger {
        display: none;
    }

    .gs-trigger:hover {
        cursor: pointer;
    }

    .gs-body {
        background: #ffffff;
        position: fixed;
        width: 480px;
        right: 24px;
        bottom: 24px;
        -webkit-box-shadow: inset 0 56px 0 0 #006a4d, 0 0 5px 0 rgb(173 173 173 / 50%);
        box-shadow: inset 0 56px 0 0 #006a4d, 0 0 5px 0 rgb(173 173 173 / 50%);

        display: none;
        opacity: 0;
    }

    .active .gs-body {
        display: block;
        opacity: 1;


        animation-timing-function: ease-in-out;
        animation: windowfade .6s;
    }

    .gs-body.dismissed {
        position: unset;
    }


    .va-wrapper {
        margin: 0 auto;
        padding: 0px;
        background: #F1F1F1;
        position: relative;
    }


    h2 {
        position: relative;
        color: #fff;
        margin: 0;
        padding: 12px 24px;
        font-weight: normal;
        font-family: 'LBJ Regular', Arial, sans-serif;
        font-size: 1.125rem;
        /*line-height: 1.5rem; */
    }

    /*
    h2::before {
        content: '';
        background-image: url('./../assets/images/pencil.svg');
        width: 28px;
        height: 33px;
        position: absolute;
        left: -50px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    */
    
    .va-ics.dismissed .va-wrapper {
        left: 0px;
        width: 100%;
}

    .va-wrapper .va-ics-buttons-wrapper {
        padding: 12px 24px 12px 24px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }    

    .va-wrapper .va-ics-comment-form-wrapper {
        padding: 24px;
        background: #ffffff;
    }    

    h4 {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #323233;
        margin:auto;
        margin-left: 0;
        flex: auto;
    }

    .va-ics-thankyou {
        /* height: 64px; */
        /* padding: 12px 24px 12px 24px; */
        justify: space-between;
        background: #F1F1F1;
        padding: 20px 24px 20px 24px;
    }

    h4.va-feedback {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: #505050;
        margin-bottom: 16px;
    }

    h4.va-feedback-2 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #505050;
        margin-bottom: 12px;
    }

    h4.va-feedback-2 span {
        /*font-weight: 300;*/
    }

    .va-wrapper .va-ics-checkmark {
        content: url(./../assets/images/Success.png);
        position: relative;
        left: 0px;
        top: 5px;
        margin-right: 8px;
        display: inline-block;
    }

    h4.va-ics-h4-thankyou {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
    }
    .va-wrapper .va-ics-close {
        content: url(./../assets/images/Close.png);
        position: relative;
        right: 1px;
        top: 7px;
        display: inline-block;
        float: right;
        cursor: pointer; 
    }

    .va-ics.dismissed .va-wrapper .va-ics-close{
        display:none;
    }

    .va-ics-buttons {
        display: inline-block;
        flex:auto;
        flex-grow: 0;
    }

    .va-ics-buttons button {
        display: inline-block;
        background: #fff;
        padding: 8px 16px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        width: 72px;
        height: 40px;
        border: 1px solid #505050;
        font-family: 'LBJ Regular', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #005ba2;
        margin-left: 8px;
        width: calc(50% - 4px);
        width:72px;
    }
    .va-ics-buttons button:first-child {
        margin-left:0px;
    }

    .va-ics-buttons button:hover {
        cursor: pointer;
        background: #05286A;
        color: #ffffff;
    }

    textarea.va-ics-feedback {
        width: 100%;
        height: 135px;
        border: 1px solid #767676;
        padding: 15px;
        resize: none;
        color: #767676;
    }

    button.va-ics-submit {
        display: inline-block;
        background: #05286A;
        color: #ffffff;
        padding: 12px 18px;
        transition: all .2s;
        /*border: 2px solid #005ba2;*/
        margin-top: 24px;
        margin-right: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }

    button.va-ics-cancel {
        display: inline-block;
        background: #005ba2;
        color: #ffffff;
        padding: 12px 18px;
        transition: all .2s;
        border: 2px solid #005ba2;
        margin-top: 15px;
    }

    button.va-ics-submit:hover {
        cursor: pointer;
    }




</style>
