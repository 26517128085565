<template>
    <div class="main-footer">
        <div class="wrapper">
            <button class="go-top" aria-label="Go back to the top of the page" @click.prevent="scrollToTop()">Back to top</button>
            <ul>
                <li><a href="https://business.bankofscotland.co.uk/business-home/legal.html" target="_blank" title="Legal" rel="noopener">Legal</a></li>
                <li><a href="https://business.bankofscotland.co.uk/business-home/accessibility.html" target="_blank" title="Accessibility" rel="noopener">Accessibility</a></li>
                <li><a href="https://www.bankofscotland.co.uk/securityandprivacy/cookies.html" target="_blank" title="Cookies" rel="noopener">Cookies</a></li>
                <li><a href="https://business.bankofscotland.co.uk/business-home/legal/personal-and-business-data.html" target="_blank" title="Privacy" rel="noopener">Privacy</a></li>
                <li><a href="https://business.bankofscotland.co.uk/help/online-security.html" target="_blank" title="Security" rel="noopener">Security</a></li>
                <!--
                <li><a href="#" title="Sitemap">Sitemap</a></li>
                -->
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainFooter',
    methods: {
        scrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if(c > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        }
    }
}

</script>

<style scoped>
    
    .main-footer {
        background: #021334;
    }

    .wrapper {
        height: 96px;
    }

    ul {
        list-style: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: 0;
        font-size: .9375em;
    }

    ul li {
        display: inline-block;
        margin-right: 36px;
    }

    ul li a {
        color: #ffffff;
        text-decoration: none;
    }

    .go-top {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        font-size: .9375em;
    }

    .go-top:hover {
        cursor: pointer;
    }

    .go-top:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        width: 6px;
        transform: rotate(-45deg) skew(-6deg, -6deg);
        color: #ffffff;
        transition: rotate 1s;
        margin: 0 0 -2px 12px;
    }

    @media only screen and (max-width: 650px) {
        .wrapper {
            height: auto;
        }

        ul {
            position: static;
            transform: none;
            padding: 0 18px 18px 18px;
        }

        ul li {
            display: block;
            margin: 0 18px 18px 0;
        }

        .go-top {
            position: static;
            padding: 24px 0 24px 0;
            border-bottom: 1px solid #bfbfbf;
            display: block;
            width: calc(100% - 36px);
            margin: 0 18px 24px 18px;
            transform: none;
            text-align: left;
        }
    }
    
</style>
