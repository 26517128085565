<template>
    <div class="modal-window">
        <div class="wrapper">
            <div class="modal-bg"></div>
            <template v-if="error">
                <main-modal-window-error></main-modal-window-error>
            </template>
        </div>
    </div>
</template>

<script>
import MainModalWindowError from '@/components/MainModalWindowError';

export default {
    name: 'MainModalWindow',
    props: ['type', 'message'],
    computed: {
        error() {
            if(this.type == 'error') {
                return true;
            }
            else {
                return false;
            }
        }
    },
    components: {
        MainModalWindowError
    },
    methods: {

    },
}
</script>

<style scoped>

    .modal-bg {
        background: #000000;
        opacity: 0.5;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }

</style>
