import { render, staticRenderFns } from "./SinglePageUpdate.vue?vue&type=template&id=b281d86e&scoped=true&"
import script from "./SinglePageUpdate.vue?vue&type=script&lang=js&"
export * from "./SinglePageUpdate.vue?vue&type=script&lang=js&"
import style0 from "./SinglePageUpdate.vue?vue&type=style&index=0&id=b281d86e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b281d86e",
  null
  
)

export default component.exports