<template>
    <div class="va-answer-page" ref="content">
        <div class="wrapper">
            <div v-if="isRequestFinished && init && engineInit">
                <single-page-notification :notification="response.data.etv.notification"></single-page-notification>
                <single-page-breadcrumbs></single-page-breadcrumbs>
                <h2>{{response.data.etv.originalquestion}}</h2>
                <single-page-answer :response="response.data.answer" :semanticsfaqs="response.data.semantics"></single-page-answer>
                <single-page-survey v-if="this.offerIcs()" :sid="response.data.ident" :tid="response.data.etv.transid"></single-page-survey>
                <single-page-footer></single-page-footer>
            </div>
            <div v-else>
                <main-ajax-spinner></main-ajax-spinner>
            </div>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';
import router from '../router';
import tealium from '../mixins/tealium.js';

import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';
import SinglePageSurvey from '@/components/SinglePageSurvey';
import MainAjaxSpinner from '@/components/MainAjaxSpinner';
import SinglePageAnswer from '@/components/SinglePageAnswer';
import SinglePageFooter from '@/components/SinglePageFooter';
import SinglePageNotification from '@/components/SinglePageNotification';
//import CookiePolicy from '@/components/CookiePolicy';

export default {
    name: 'ViewWhatsnewPage',
    components: {
        SinglePageBreadcrumbs,
        MainAjaxSpinner,
        SinglePageAnswer,
        SinglePageFooter,
        SinglePageNotification,
        SinglePageSurvey,
        //CookiePolicy
    },
    mixins: [tealium],
    data() {
        return {
            rec: {
                rid: 488, // what's new recognition id
                aid: 834 // what's new answer id
            },
            debug: false
        }
    },

    // What's new page is static, meaning it's tied to specific recid and ans id

    watch: {
        init(n) {
            this.debug && console.log('e02');
            n && this.categoryInit && Event.$emit('va.request', {type: 'selectSingleFaq', rid: this.rec.rid, aid: this.rec.aid, question: 'whats new'});
        },

        categoryInit(n) {
            this.debug && console.log('e03');
            n && this.init && Event.$emit('va.request', {type: 'selectSingleFaq', rid: this.rec.rid, aid: this.rec.aid, question: 'whats new'});
        },

        response(n) {
            (this.response.data.etv.answertype == 'Safety Net') && router.push({path: '/404'});
        },
    },

    created() {
        if(this.isSessionValid() && this.categoryInit) {
            this.debug && console.log('e04');
            Event.$emit('va.request', {type: 'selectSingleFaq', rid: this.rec.rid, aid: this.rec.aid, question: 'whats new'});
        }
    },

    computed: {
        categoryInit() {
            return store.state.categoriesInit;
        },

        init() {
            return store.state.init;
        },

        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response() {
            return store.state.responseData;
        },

        token() {
            return store.state.token;
        },
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next(vm => {
            window.scrollTo(0, 0);
            vm.tealiumRouteTracking(to);
        });
    },

    beforeRouteUpdate(to, from, next) {
        this.debug && console.log('e05');
        Event.$emit('va.request', {type: 'selectSingleFaq', rid: this.rec.rid, aid: this.rec.aid, question: 'whats new'});
        window.scrollTo(0, 0);
        store.dispatch('setSearchStatus', false); // reset search status
        this.tealiumRouteTracking(to);
        next();
    },

    methods: {
        isSessionValid: function() {
            let valid = false;
            let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
            
            if(session && this.token.length > 0) {
                valid = true;
            }
            else {
                this.debug && console.log('e01');
                Event.$emit('va.init', {init: 1});
            }
            
            return valid;
        },

        offerIcs() {
            if(this.response.data.etv.showicsoverlay == '1') {
                return true;
            }
            else {
                return false;
            }
        },
    },
}
</script>

<style scoped>

    .wrapper {
        min-height: calc(100vh - 240px);
        position: relative;
        max-width: 930px;
        margin: 0 auto;
        padding-top: 30px;
        padding-top: 15px;
    }

    h2 {
        font-family: 'Pill Gothic', Arial, Helvetica, sans-serif;
        font-size: 3em;
        font-weight: 500;
        color: #05286A;
        padding: 0 0 16px 0;
        margin: 0;
        line-height: 54px;
    }

    @media only screen and (max-width: 966px) {
        .va-answer-page {
            padding: 0 18px 0 18px;
        }
    }

</style>


